import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import { RequestForm } from "../../components/form";
import { useMedia } from "use-media";
import { KeyResourcesColor } from "../../components/common/KeyResources";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import {
  HrItSupport_November,
  HrItSupportMobile_November,
  OnboardingMobile_November,
  Onboarding_November,
} from "../conversational-ai-platform";

const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/header_customer_banner_mobile.svg");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const conAi1 = require("../../assets/img/home_page_assist/all_features_con_ai_1.png");
const conAi2 = require("../../assets/img/home_page_assist/all_features_con_ai_2.png");
const conAi3 = require("../../assets/img/home_page_assist/all_features_con_ai_3.png");
const conAi4 = require("../../assets/img/home_page_assist/all_features_con_ai_4.png");
const conAi5 = require("../../assets/img/home_page_assist/all_features_con_ai_5.png");
const conAi6 = require("../../assets/img/home_page_assist/all_features_con_ai_6.png");
const conAi7 = require("../../assets/img/home_page_assist/all_features_con_ai_7.png");
const conAi8 = require("../../assets/img/home_page_assist/all_features_con_ai_8.png");
const conAi9 = require("../../assets/img/home_page_assist/all_features_con_ai_9.png");
const conAi10 = require("../../assets/img/home_page_assist/all_features_con_ai_10.png");
const conAi11 = require("../../assets/img/home_page_assist/all_features_con_ai_11.png");
const conAi12 = require("../../assets/img/home_page_assist/all_features_con_ai_12.png");
const conAi13 = require("../../assets/img/home_page_assist/all_features_con_ai_13.png");
const conAi14 = require("../../assets/img/home_page_assist/all_features_con_ai_14.png");
const conAi15 = require("../../assets/img/home_page_assist/all_features_con_ai_15.png");
const conAi16 = require("../../assets/img/home_page_assist/all_features_con_ai_16.png");

const knowAi1 = require("../../assets/img/home_page_assist/all_features_know_ai_1.png");
const knowAi2 = require("../../assets/img/home_page_assist/all_features_know_ai_2.png");
const knowAi3 = require("../../assets/img/home_page_assist/all_features_know_ai_3.png");
const knowAi4 = require("../../assets/img/home_page_assist/all_features_know_ai_4.png");
const knowAi5 = require("../../assets/img/home_page_assist/all_features_know_ai_5.png");
const knowAi6 = require("../../assets/img/home_page_assist/all_features_know_ai_6.png");
const knowAi7 = require("../../assets/img/home_page_assist/all_features_know_ai_7.png");

const count_one = require("../../assets/img/home_page_assist/count_one.svg");
const count_two = require("../../assets/img/home_page_assist/count_two.svg");
const count_three = require("../../assets/img/home_page_assist/count_three.svg");
const count_four = require("../../assets/img/home_page_assist/count_four.svg");

const liveInbox1 = require("../../assets/img/home_page_assist/all_features_live_inbox_1.png");
const liveInbox2 = require("../../assets/img/home_page_assist/all_features_live_inbox_2.png");
const liveInbox3 = require("../../assets/img/home_page_assist/all_features_live_inbox_3.png");
const liveInbox4 = require("../../assets/img/home_page_assist/all_features_live_inbox_4.png");
const liveInbox5 = require("../../assets/img/home_page_assist/all_features_live_inbox_5.png");
const liveInbox6 = require("../../assets/img/home_page_assist/all_features_live_inbox_6.png");
const liveInbox7 = require("../../assets/img/home_page_assist/all_features_live_inbox_7.png");
const liveInbox8 = require("../../assets/img/home_page_assist/all_features_live_inbox_8.png");
const liveInbox9 = require("../../assets/img/home_page_assist/all_features_live_inbox_9.png");
const liveInbox10 = require("../../assets/img/home_page_assist/all_features_live_inbox_10.png");
const liveInbox11 = require("../../assets/img/home_page_assist/all_features_live_inbox_11.png");
const liveInbox12 = require("../../assets/img/home_page_assist/all_features_live_inbox_12.png");
const liveInbox13 = require("../../assets/img/home_page_assist/all_features_live_inbox_13.png");
const liveInbox14 = require("../../assets/img/home_page_assist/all_features_live_inbox_14.png");
const liveInbox15 = require("../../assets/img/home_page_assist/all_features_live_inbox_15.png");

const workFlow1 = require("../../assets/img/home_page_assist/all_features_workflow_automation_1.png");
const workFlow2 = require("../../assets/img/home_page_assist/all_features_workflow_automation_2.png");
const workFlow3 = require("../../assets/img/home_page_assist/all_features_workflow_automation_3.png");
const workFlow4 = require("../../assets/img/home_page_assist/all_features_workflow_automation_4.png");
const workFlow5 = require("../../assets/img/home_page_assist/all_features_workflow_automation_5.png");
const workFlow6 = require("../../assets/img/home_page_assist/all_features_workflow_automation_6.png");
const workFlow7 = require("../../assets/img/home_page_assist/all_features_workflow_automation_7.png");
const workFlow8 = require("../../assets/img/home_page_assist/all_features_workflow_automation_8.png");
const workFlow9 = require("../../assets/img/home_page_assist/all_features_workflow_automation_9.png");
const workFlow10 = require("../../assets/img/home_page_assist/all_features_workflow_automation_10.png");

const onboard_one = require("../../assets/img/home_page_assist/4x_itsm_onboarding_1.png");
const onboard_two = require("../../assets/img/home_page_assist/4x_itsm_onboarding_2.png");
const onboard_three = require("../../assets/img/home_page_assist/4x_am_onboarding_3.png");
const onboard_four = require("../../assets/img/home_page_assist/4x_itsm_onboarding_4.png");
const onboard_five = require("../../assets/img/home_page_assist/4x_itsm_onboarding_5.png");

const TopImage = require("../../assets/img/feature/approvals/approvals_header.png");

const OgImage = require("../../assets/img/home_page_assist/home_header_assist.png");

const hammer_logo = require("../../assets/images/testimonials/hammer_logo.svg");
const star_image = require("../../assets/images/testimonials/star.svg");
const capterra_logo = require("../../assets/images/testimonials/capterra_logo.svg");
const hammer_manager_mobile = require("../../assets/images/testimonials/reviewer_circle.png");
const reviewer_image = require("../../assets/images/testimonials/reviewer_circle.png");
const quotes = require("../../assets/images/testimonials/quotes.svg");

const video = require("../../assets/video/Workativ_Feature.mp4");

const resoucesData = [
  {
    //image: blogImg,
    header: "Virtual Agent",
    text: "Virtual Agent can automate 80% of repetitive IT issues",
    content:
      "Workativ helps you automate  your employee  IT issues and service requests with an intelligent virtual agent, combined with  IT workflow  automation.",
    url: "/conversational-ai-platform/virtual-agent",
    link: "Learn more",
    alt: "vitrual agent",
    color: "green",
  },
  {
    //image: whitepaperImg,
    header: "IT Helpdesk Chatbot",
    text: "80% of repetitive IT issues can be automated",
    content:
      "Workativ helps you automate your employee IT issues and service requests with intelligent IT Helpdesk Chatbot, combined with IT Workflow Automation.",
    url: "/conversational-ai-platform/it-helpdesk-chatbot",
    link: "Learn more",
    alt: "IT helpdesk chatbot",
    color: "yellow",
  },
  {
    //image: videoImg,
    header: "HR Chatbot",
    text: "Deliver smarter employee experience with HR Chatbot",
    content:
      "Workativ helps you automate most of your employee HR enquires and requests using an advanced HR chatbot with in-built HR workflow automation.",
    url: "/conversational-ai-platform/hr-chatbot",
    link: "Learn more",
    alt: "HR chatbot",
    color: "red",
  },
];
if (typeof window !== "undefined") {
  window.onload = function () {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          const scrollAdjust = 100;
          window.scrollBy(0, -scrollAdjust);
        }, 1000);
      }
    }
  };
}

export default function DialogDesigner() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Workativ Assistant Features"
        description="Explore workativ assistant Features,Finally, a single platform with both - conversational ai chatbot and app workflow automation"
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={OgImage}
        ogTitle="Conversational AI Chatbot Designer | Workativ Assistant Features"
        ogDescription="Explore workativ assistants' conversational ai platform with advanced NLP and context management etc."
      />
      <div className="all_features_wrapper support_chatbot_it_help features_updated_nov">
        <Container>
          <Layout
            backgroundColor={"bg_slider_home pad_0 bg_color"}
            logoFor="ASSISTANT"
          >
            <section className="main-slider bg_slider_home features_header_home bg_color padding-bottom-mobile-features-assist">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0 align_center_content">
                    <div className="main-slider-left ibm_inte_header">
                      <h1 className="font-page-header">
                        Transform your workplace support in minutes
                      </h1>
                      <p className="pr-0 font-section-normal-text pb-0 mb-0">
                        Finally, a single platform that delivers bots, app
                        workflows, discovers knowledge, and provides live
                        support to make your life easier.
                      </p>
                      <RequestForm isFooterForm={false} />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pb-xs-4  padding-bottom-mobile-features flex_home">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 features_change_img_header pl-0 pr-0">
                      <div className="features_change_img">
                        <div className="features_change_img-center">
                          <video loop autoPlay muted id="vid">
                            <source src={video} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div
              className={`float-left w-100 ${
                isSmall ? "mb-4 mt-4" : "margin-bottom-40"
              }`}
            >
              <div className="container mb-2">
                <h5
                  className={`text-center font-section-normal-text-medium-customer-banner ${
                    isSmall ? "mb-3" : "margin-bottom-20 "
                  }`}
                >
                  Join hundreds of industry leaders
                </h5>{" "}
                {isSmall ? (
                  <img className="" src={bannerImageMobile} />
                ) : (
                  <img className="  d-flex m-auto" src={customerBanner} />
                )}
              </div>
            </div>
            {isSmall ? (
              <section
                id="feature-one"
                className="features_all_case_parent_div  features-all-con-ai float-left w-100"
              >
                <HrItSupportMobile_November
                  tabContent={tabsForConAi}
                  imagesToPreload={allFeatureImages}
                  header={conAiHeaderMobile}
                  isComingSoon={tabsForConAi.isComingSoon}
                  isHeaderwithImage={true}
                  headerImage={count_one}
                />
              </section>
            ) : (
              <section
                id="feature-one"
                className="features_all_case_parent_div  features-all-con-ai  float-left w-100"
              >
                <HrItSupport_November
                  tabContent={tabsForConAi}
                  imagesToPreload={allFeatureImages}
                  header={conAiHeader}
                  headerGroupByCountList={[5, 6, 5]}
                  isComingSoon={tabsForConAi.isComingSoon}
                  isHeaderwithImage={true}
                  headerImage={count_one}
                />
              </section>
            )}
            {isSmall ? (
              <section
                id="feature-two"
                className="features_all_case_parent_div features-all-know-ai  float-left w-100"
              >
                <HrItSupportMobile_November
                  tabContent={tabsForKnowledgeAi}
                  imagesToPreload={allFeatureImages}
                  header={knowledgeAiHeaderMobile}
                  isComingSoon={tabsForKnowledgeAi.isComingSoon}
                  isHeaderwithImage={true}
                  headerImage={count_two}
                />
              </section>
            ) : (
              <section
                id="feature-two"
                className="features_all_case_parent_div features-all-know-ai  float-left w-100"
              >
                <HrItSupport_November
                  tabContent={tabsForKnowledgeAi}
                  imagesToPreload={allFeatureImages}
                  header={knowledgeAiHeader}
                  headerGroupByCountList={[4, 3]}
                  isComingSoon={tabsForKnowledgeAi.isComingSoon}
                  isHeaderwithImage={true}
                  headerImage={count_two}
                />
              </section>
            )}
            {isSmall ? (
              <section
                id="feature-three"
                className="features_all_case_parent_div features-all-app-workflow  float-left w-100"
              >
                <HrItSupportMobile_November
                  tabContent={tabsForAppWorkflow}
                  imagesToPreload={allFeatureImages}
                  header={appWorkflowHeaderMobile}
                  isComingSoon={tabsForAppWorkflow.isComingSoon}
                  isHeaderwithImage={true}
                  headerImage={count_three}
                />
              </section>
            ) : (
              <section
                id="feature-three"
                className="features_all_case_parent_div  features-all-app-workflow float-left w-100"
              >
                <HrItSupport_November
                  tabContent={tabsForAppWorkflow}
                  imagesToPreload={allFeatureImages}
                  header={appWorkflowHeader}
                  headerGroupByCountList={[5, 5]}
                  isComingSoon={tabsForKnowledgeAi.isComingSoon}
                  isHeaderwithImage={true}
                  headerImage={count_three}
                />
              </section>
            )}
            {isSmall ? (
              <section
                id="feature-four"
                className="features_all_case_parent_div  features-all-shared-inbox float-left w-100"
              >
                <HrItSupportMobile_November
                  tabContent={tabsForSharedLiveBox}
                  imagesToPreload={allFeatureImages}
                  header={sharedInboxHeaderMobile}
                  isComingSoon={tabsForSharedLiveBox.isComingSoon}
                  isHeaderwithImage={true}
                  headerImage={count_four}
                />
              </section>
            ) : (
              <section
                id="feature-four"
                className="features_all_case_parent_div features-all-shared-inbox  float-left w-100"
              >
                <HrItSupport_November
                  tabContent={tabsForSharedLiveBox}
                  imagesToPreload={allFeatureImages}
                  header={sharedInboxHeader}
                  headerGroupByCountList={[5, 5, 5]}
                  isComingSoon={tabsForSharedLiveBox.isComingSoon}
                  isHeaderwithImage={true}
                  featurIsComingSoon={true}
                  headerImage={count_four}
                />
              </section>
            )}

            <RequestForm isFooterForm={true} />
            {isSmall ? (
              <OnboardingMobile_November
                tabContent={tabsForOnBoarding}
                imagesToPreload={tabsForOnBoardingImages}
              />
            ) : (
              <Onboarding_November
                tabContent={tabsForOnBoarding}
                imagesToPreload={tabsForOnBoardingImages}
              />
            )}
            {isSmall ? (
              <TestiMonailsFeaturesMobile_Nov />
            ) : (
              <TestiMonailsFeatures_Nov />
            )}
            {/* {isSmall ? null : <OnScrollPopup />} */}
            <KeyResourcesColor cards={resoucesData} />
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <div className="isfooter_form">
              <RequestForm isFooterForm={true} />
            </div>
          </Layout>
        </Container>
      </div>
    </>
  );
}

const conAiHeader =
  'Explore our top <span class=" text-decoration-underline text-underline-offset feature-heading-colored">Conversational AI Platform</span> features';
const knowledgeAiHeader =
  'Explore our top <span class=" text-decoration-underline text-underline-offset feature-heading-colored"> Knowledge AI</span> features';
const appWorkflowHeader =
  'Explore our top  <span class=" text-decoration-underline text-underline-offset feature-heading-colored">  App Workflow Automation</span> features';
const sharedInboxHeader =
  'Explore our top <span class="feature-heading-colored"><span class="text-decoration-underline text-underline-offset">Shared Live Inbox</span><sup style="top: -0.3em; color: #E05959; font-size: 24px">*</sup></span> features';

const conAiHeaderMobile =
  'Explore our top <br/><span class=" text-decoration-underline text-underline-offset feature-heading-colored">Conversational AI Platform</span><br/> features';
const knowledgeAiHeaderMobile =
  'Explore our top <br/><span class=" text-decoration-underline text-underline-offset feature-heading-colored"> Knowledge AI</span><br/> features';
const appWorkflowHeaderMobile =
  'Explore our top <br/><span class=" text-decoration-underline text-underline-offset feature-heading-colored"> App Workflow Automation</span><br/> features';
const sharedInboxHeaderMobile =
  'Explore our top <br/> <span class="feature-heading-colored"><span class="text-decoration-underline text-underline-offset">Shared Live Inbox</span><sup style="top: -0.1em; color: #E05959; font-size: 20px">*</sup></span><br/> features';

function TestiMonailsFeatures_Nov() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="onboarding_testimoanials_main_container features_testimonials_updated_nov w-100 float-left">
      <div className="container">
        <div className="row">
          <div className="main_container_testimonials_usecase testimonials_container_support">
            <div className="top_image_container_testimonials_usecase top_image_container_testimonials_it_support">
              <img
                loading="lazy"
                className="capterra_logo_left capterra_usecase"
                src={capterra_logo}
              />
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <span className="font-section-small-text">5/5</span>
            </div>
            <div className="middle_content_container_testimonials_usecase">
              <h5 className="font-section-sub-header">
                “Workativ delivers and the implementation team is top notch.
                Very supportive and responsive to question”
              </h5>
              <p className="font-section-normal-text-testimonials">
                <span className="font-section-normal-text-medium color-black">
                  Overall:&nbsp;
                </span>
                Very positive experience. We are able to grow the bot based on
                our internal needs. The cost and support can’t be beat for all
                the features the tool provides
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-medium color-black">
                  Pros:&nbsp;
                </span>
                It just seems to work out of the box. If you have a decent
                outline of the needs the bot needs to fill it is really
                straightforward to set up and test. Their deployment team offers
                insight on where to focus time and provides resources to go to
                quickly resolve questions on your own. Truly self service.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-medium color-black">
                  Cons:&nbsp;
                </span>
                No complaints right now - when we have had issues they respond
                quickly with a plan and their follow through is excellent
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-medium color-black">
                  Reasons for Choosing Workativ Assistant:&nbsp;
                </span>
                Cost and the willingness of Workativ to answer questions and
                ensure our internal business requirements were being met.
              </p>
            </div>
            <div className="bottom_button_content_container_testimonials">
              <div className="bottom_reviewer_image_container_testimonials_usecase">
                <img
                  loading="lazy"
                  src={reviewer_image}
                  className="testimonial_reviewer_image_landing_page"
                  alt="hammer_manager"
                ></img>
                <div className="end_name_usecase">
                  <h5
                    className="font-section-sub-header-bold-goto-case hammer_dir_name mb-0"
                    style={{ color: "#4F75D9" }}
                  >
                    Dan Bottiger
                  </h5>
                  <h5 className="font-section-normal-text-medium reviewer_designation_hammer mb-0">
                    Director of Information Systems
                  </h5>

                  <img
                    src={hammer_logo}
                    className="it_support_hammer_logo h-auto"
                  ></img>
                </div>
              </div>
              <button
                className="goto_case_study_button_redirection button_usecase_it_support w-30 mt-3 h-fit-content"
                onClick={() => {
                  window.location.href =
                    "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation";
                }}
              >
                <span className="font-section-normal-text-medium">
                  Explore success stories
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function TestiMonailsFeaturesMobile_Nov() {
  return (
    <section class="testimonial_background_main_container_mobile it_support_mobile_testimonials float-left">
      <h3 className="font-section-sub-header text-align-center mb-3">
        Customer testimonial
      </h3>
      <div className="testimonial_feedback_box_mobile home_assistant_tesimonials_content_mobile">
        <div className="testimonial_feedback_top_content_box_mobile">
          <p className="font-section-normal-text-testimonials text-align-left line-height-18">
            <span>
              <img
                src={quotes}
                className="quotes_image_testimonial h-auto"
              ></img>
            </span>
            The setup and building of the Workativ Chatbot I felt was extremely
            well supported! My questions were answered quickly and at no point
            did I feel unsupported. I had multiple Zoom meetings through the
            process where we would meet to go through the build.
          </p>
          <p className="font-section-normal-text-testimonials text-align-left line-height-18">
            When the build hit a hurdle, the resolution was found and
            implemented within 24-48 hours. Through this communication was
            excellent.
          </p>
          <p className="font-section-normal-text-testimonials text-align-left line-height-18 mb-2">
            I felt listened through what we absolutely had to have for this tool
            to be rolled out in our environment. Throughout the process we kept
            looking back at the business requirements to make sure we were
            aligning properly.
          </p>
        </div>

        <img
          src={hammer_manager_mobile}
          className="testimonial_reviewer_image h-auto"
          alt="hammer_global_manager"
          style={{ width: "80px" }}
        ></img>

        <div className="bottom_designation_box_right_mobile ">
          <h5
            className="font-section-sub-header-smaller-bold-testimonials mt-3"
            style={{ color: "#4F75D9" }}
          >
            Dan Bottiger
          </h5>
          <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
            Director of Information Systems
          </h5>
          <img src={hammer_logo} className="w-35 h-auto"></img>
        </div>
        <button
          className="goto_case_study_button_redirection w-100 mt-3"
          onClick={() => {
            window.location.href =
              "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation";
          }}
        >
          <span className="font-section-normal-text-medium">
            Explore success stories
          </span>
        </button>
      </div>
    </section>
  );
}
const tabsForConAi = [
  {
    header: "Chatbot Builder",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">
            Simple and easy to use chatbot builder canvas
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Build awesome dialogs and engaging end-user experience using no-code
            chatbot builder and advanced dialog management capability.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
            Be the conversational ai champion for your workplace and unlock your
            creative chatbot building skills.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/chatbot-builder"
          >
            More on chatbot builder &nbsp; &#8594;
          </a>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi1} alt="Chatbot Builder"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Simple and easy to use chatbot builder canvas
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Build awesome dialogs and engaging end-user experience using no-code
          chatbot builder and advanced dialog management capability.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Be the conversational ai champion for your workplace and unlock your
          creative chatbot building skills.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/chatbot-builder"
        >
          More on chatbot builder &nbsp; &#8594;
        </a>
        <img src={conAi1} alt="Chatbot Builder"></img>
      </>
    ),
  },
  {
    header: "FAQ Builder",
    hasGridLayoutLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Intuitive FAQ bot builder
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
            Simple and easy to use UI to automate your FAQs in minutes. Key user
            queries, utterances and answers to automate all your manual FAQs and
            go-live instantly.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/faq-chatbot-builder"
          >
            More on FAQ builder &nbsp; &#8594;
          </a>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi2} alt="FAQ"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Intuitive FAQ bot builder
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
          Simple and easy to use UI to automate your FAQs in minutes. Key user
          queries, utterances and answers to automate all your manual FAQs and
          go-live instantly.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/faq-chatbot-builder"
        >
          More on FAQ builder &nbsp; &#8594;
        </a>
        <img src={conAi2} alt="FAQ"></img>
      </>
    ),
  },

  {
    header: "Bot Marketplace",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Go-live instantly with pre-built templates for your chatbot
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
            No time to build? No problem. Just download pre-built Bots, 100+ app
            integrations, and 1000+ pre-built app workflows for chatbot in a
            single click and go live in minutes. No coding.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/pre-built-chatbot-template-marketplace"
          >
            More on bot marketplace &nbsp; &#8594;
          </a>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi3} alt="Bot MarketPlace"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Go-live instantly with pre-built templates for your chatbot
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
          No time to build? No problem. Just download pre-built Bots, 100+ app
          integrations, and 1000+ pre-built app workflows for chatbot in a
          single click and go live in minutes. No coding.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/pre-built-chatbot-template-marketplace"
        >
          More on bot marketplace &nbsp; &#8594;
        </a>
        <img src={conAi3} alt="Bot MarketPlace"></img>
      </>
    ),
  },

  {
    header: "Omnichannel",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container h-100 d-flex position-relative flex-column omini-channel-content-start">
          <h3 className="font-section-normal-text-medium-link">
            Chatbot on MS Teams, Slack, or as a Chat Widget
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Turn your favorite collaboration app into modern employee
            self-service by deploying chatbot on your Teams, Slack or as Widget
            on your self-help portal in few clicks.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
            Customize the end-user experience by applying your company branding,
            logo etc.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue "
            href="/conversational-ai-platform/chatbot-channels"
          >
            More on omnichannel &nbsp; &#8594;
          </a>
          <div className="d-flex gap-10 align-items-baseline mt-0 position-absolute bottom-60px">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps all-feature-font-size color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps all-feature-font-size color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps all-feature-font-size color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="support_content_right_container it_support_right_content_containe">
          <img src={conAi4} alt="ominichannel"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Chatbot on MS Teams, Slack, or as a Chat Widget
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Turn your favorite collaboration app into modern employee self-service
          by deploying chatbot on your Teams, Slack or as Widget on your
          self-help portal in few clicks.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Customize the end-user experience by applying your company branding,
          logo etc.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/chatbot-channels"
        >
          More on omnichannel &nbsp; &#8594;
        </a>
        <div className="icons_container_onboarding mt-0 mb-4">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={conAi4} alt="ominichannel"></img>
      </>
    ),
  },
  {
    header: "Live Agent Handover",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Live Agent Handover
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 ">
            {" "}
            Workativ offers seamless ‘agent handover’ of chatbot to live agents
            with complete context and user conversation history so your agents
            can pick up right where the bot left, avoiding any user frustration
            – but most importantly solving end-user issues right away.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
            Make your MS Teams a live agent channel instantly.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue "
            href="/conversational-ai-platform/agent-handover-chatbot"
          >
            More on agent handover &nbsp; &#8594;
          </a>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi5} alt="agent handover"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Live Agent Handover
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 ">
          {" "}
          Workativ offers seamless ‘agent handover’ of chatbot to live agents
          with complete context and user conversation history so your agents can
          pick up right where the bot left, avoiding any user frustration – but
          most importantly solving end-user issues right away.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
          Make your MS Teams a live agent channel instantly.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/agent-handover-chatbot"
        >
          More on agent handover &nbsp; &#8594;
        </a>
        <img src={conAi5} alt="agent handover"></img>
      </>
    ),
  },
  {
    header: "App Integrations",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Calling app workflows from chatbot
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18">
            Because we understand automating workplace support requires bots
            that are task oriented, we designed our platform to effortlessly
            unify chatbot and app workflow automation in a single platform so
            you can integrate chatbot with apps and call app workflows in a few
            clicks.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            No more java scripting, custom development, backend integration etc.
            just click and go.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi6} alt="app integration"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Calling app workflows from chatbot
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Because we understand automating workplace support requires bots that
          are task oriented, we designed our platform to effortlessly unify
          chatbot and app workflow automation in a single platform so you can
          integrate chatbot with apps and call app workflows in a few clicks.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          No more java scripting, custom development, backend integration etc.
          just click and go.
        </p>
        <img src={conAi6} alt="app integration"></img>
      </>
    ),
  },
  {
    header: "Grouping",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Grouping</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            With Grouping feature, you can organize Dialogs specific to
            departments or categories and set up conditional flows. This allows
            you to control who can access Dialogs or FAQs within a group, making
            it easier to address user queries based on factors like location or
            role.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi7} alt="grouping"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Grouping</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          With Grouping feature, you can organize Dialogs specific to
          departments or categories and set up conditional flows. This allows
          you to control who can access Dialogs or FAQs within a group, making
          it easier to address user queries based on factors like location or
          role.
        </p>
        <img src={conAi7} alt="grouping"></img>
      </>
    ),
  },
  {
    header: "Context Switching",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Context Switching
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            User conversations are never singular. A typical conversation may
            involve more than one context and goal, and even more confusing when
            the user decides to change the request halfway. Workativ handles
            context switching swiftly without loosing users.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi8} alt="context switching"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Context Switching
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          User conversations are never singular. A typical conversation may
          involve more than one context and goal, and even more confusing when
          the user decides to change the request halfway. Workativ handles
          context switching swiftly without loosing users.
        </p>
        <img src={conAi8} alt="context switching"></img>
      </>
    ),
  },
  {
    header: "Dynamic Notifications",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container pb-40">
          <h3 className="font-section-normal-text-medium-link">
            Dynamic Notifications
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Proactively notify users on chat to take action at workplace. Be it
            updating their passwords, filling surveys, updating OKRs, personal
            info, or simply surprise them on their birthdays/anniversaries, etc.
            Never let your employees miss a beat.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Bot notifications can be setup using app triggers that can initiate
            the bot to take an action. e.g password expiry, employee onboarding
            etc. Endless possibilities.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi9} alt="dynamic notification"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Dynamic Notifications
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Proactively notify users on chat to take action at workplace. Be it
          updating their passwords, filling surveys, updating OKRs, personal
          info, or simply surprise them on their birthdays/anniversaries, etc.
          Never let your employees miss a beat.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Bot notifications can be setup using app triggers that can initiate
          the bot to take an action. e.g password expiry, employee onboarding
          etc. Endless possibilities.
        </p>
        <img src={conAi9} alt="dynamic notification"></img>
      </>
    ),
  },
  {
    header: "Live Testing",
    hasGridLayoutLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Live Testing</h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Testing feature empowers you to experiment and fine-tune your
            chatbot without publishing changes. You can test your chatbot’s
            capabilities using the “Try Me” feature, ensuring a seamless user
            experience. Ensure your chatbot is always at its best without
            affecting the live environment.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi10} alt="live testing"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Live Testing</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Testing feature empowers you to experiment and fine-tune your chatbot
          without publishing changes. You can test your chatbot’s capabilities
          using the “Try Me” feature, ensuring a seamless user experience.
          Ensure your chatbot is always at its best without affecting the live
          environment.
        </p>
        <img src={conAi10} alt="live testing"></img>
      </>
    ),
  },
  {
    header: "Disambiguate",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Manage Ambiguity
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            User conversations are never simple and can easily cause ambiguity
            for the chatbot. A true conversational ai platform must
            disambiguate.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Using the dialog management tools in workativ, you can use this
            feature so chatbot can “clarify” user request or intent if the
            request is unclear or confusing.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi11} alt="manage ambiguity"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Manage Ambiguity
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          User conversations are never simple and can easily cause ambiguity for
          the chatbot. A true conversational ai platform must disambiguate.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Using the dialog management tools in workativ, you can use this
          feature so chatbot can “clarify” user request or intent if the request
          is unclear or confusing.
        </p>
        <img src={conAi11} alt="manage ambiguity"></img>
      </>
    ),
  },
  {
    header: "Sensitive Data Handling",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">
            Sensitive Data Handling
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            It gives the ability for the admin to securely handle the user
            information provided to the bot and resolve the user query without
            storing or displaying the user information.&nbsp;
            <span className="font-section-normal-text-testimonials-medium color-black">
              Example:
            </span>{" "}
            User wants to reset their password and provides the new password to
            the chatbot, and the bot will update the password and will not store
            or display.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi12} alt="sensitive data handling"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Sensitive Data Handling
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          It gives the ability for the admin to securely handle the user
          information provided to the bot and resolve the user query without
          storing or displaying the user information.&nbsp;
          <span className="font-section-normal-text-testimonials-medium color-black">
            Example:
          </span>{" "}
          User wants to reset their password and provides the new password to
          the chatbot, and the bot will update the password and will not store
          or display.
        </p>
        <img src={conAi12} alt="sensitive data handling"></img>
      </>
    ),
  },
  {
    header: "Location Personalization",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Location Personalization
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            With Location Personalization, you can create multiple Dialogs and
            provide region-specific responses, ensuring users receive
            contextually relevant information. Engage users based on their
            location, increasing the relevance and effectiveness of your
            responses.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container ">
          <img src={conAi13} alt="location personnalization"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Location Personalization
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 ">
          With Location Personalization, you can create multiple Dialogs and
          provide region-specific responses, ensuring users receive contextually
          relevant information. Engage users based on their location, increasing
          the relevance and effectiveness of your responses.
        </p>
        <img src={conAi13} alt="location personalization"></img>
      </>
    ),
  },
  {
    header: "Branding",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">Branding</h3>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Apply company branding such as name, description, colors, logo, and
            look and feel to your chatbot widget easily in few seconds and
            preview your results instantly using widget settings.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi14} alt="branding"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Branding</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Apply company branding such as name, description, colors, logo, and
          look and feel to your chatbot widget easily in few seconds and preview
          your results instantly using widget settings.
        </p>
        <img src={conAi14} alt="branding"></img>
      </>
    ),
  },
  {
    header: "Analytics",
    hasGridLayoutLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">
            Chatbot analytics
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
            Get a deeper understanding of chatbot usage by employees. Track how
            employees use chatbots, drill down on most helpful conversations,
            peak durations, average time to resolution, most frequently used
            channels and more.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/chatbot-analytics-performance"
          >
            More on analytics &nbsp; &#8594;
          </a>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi15} alt="analytics" className="w-95"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Chatbot analytics
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
          Get a deeper understanding of chatbot usage by employees. Track how
          employees use chatbots, drill down on most helpful conversations, peak
          durations, average time to resolution, most frequently used channels
          and more.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/chatbot-analytics-performance"
        >
          More on analytics &nbsp; &#8594;
        </a>
        <img src={conAi15} alt="analytics"></img>
      </>
    ),
  },
  {
    header: "Chat History",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">Chat History</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Using Chat History, you can quickly comb through conversations to
            possibly spot unsuccessful conversation and improve chatbot
            effectiveness. Analyze chatbot and user conversations, user
            experience, and end-user feedback.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={conAi16} alt="chat history"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Chat History</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Using Chat History, you can quickly comb through conversations to
          possibly spot unsuccessful conversation and improve chatbot
          effectiveness. Analyze chatbot and user conversations, user
          experience, and end-user feedback.
        </p>
        <img src={conAi16} alt="chat history"></img>
      </>
    ),
  },
];
const tabsForKnowledgeAi = [
  {
    header: "Knowledge AI",
    hasGridLayoutLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">Knowledge AI</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
            Leverage the power of Large Language Model (LLM) and Generative AI
            to super charge your knowledge base and respond to user queries in
            seconds.
          </p>

          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/knowledge-ai"
          >
            More on knowledge ai &nbsp; &#8594;
          </a>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={knowAi1} alt="knowledgeai"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Knowledge AI</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Leverage the power of Large Language Model (LLM) and Generative AI to
          super charge your knowledge base and respond to user queries in
          seconds.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/knowledge-ai"
        >
          More on knowledge ai &nbsp; &#8594;
        </a>
        <img src={knowAi1} alt="knowledgeai"></img>
      </>
    ),
  },
  {
    header: "Create Articles",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    isComingSoon: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container d-flex flex-column justify-content-center h-100 position-relative">
          <h3 className="font-section-normal-text-medium-link">
            Create Articles
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
            Create knowledge articles with simple and easy to use editor.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/knowledge-ai"
          >
            More on knowledge ai &nbsp; &#8594;
          </a>

          <span className="font-section-small-signup-form-medium position-absolute bottom-30px">
            {" "}
            <sup
              style={{
                top: "0em",
                color: "#E05959",
                fontSize: "16px",
              }}
            >
              *
            </sup>
            Coming Soon
          </span>
        </div>
        <div className="support_content_right_container it_support_right_content_container d-flex justify-content-center">
          <img src={knowAi2} alt="create article" className="w-95"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Create Articles
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18  mb-2 pb-0">
          Create knowledge articles with simple and easy to use editor.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/knowledge-ai"
        >
          More on knowledge ai &nbsp; &#8594;
        </a>
        <span className="font-section-small-signup-form-medium  mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={knowAi2} alt="create article"></img>
      </>
    ),
  },
  {
    header: "Upload Files",
    isComingSoon: true,
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container  d-flex flex-column justify-content-center h-100 position-relative">
          <h3 className="font-section-normal-text-medium-link">Upload Files</h3>

          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
            Simply upload your knowledge files in most popular file formats such
            word, pdf etc to train the bot in in minutes to answer user queries.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/knowledge-ai"
          >
            More on knowledge ai &nbsp; &#8594;
          </a>
          <span className="font-section-small-signup-form-medium position-absolute bottom-30px">
            {" "}
            <sup
              style={{
                top: "0em",
                color: "#E05959",
                fontSize: "16px",
              }}
            >
              *
            </sup>
            Coming Soon
          </span>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={knowAi3} alt="Upload Files"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Upload Files</h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
          Simply upload your knowledge files in most popular file formats such
          word, pdf etc to train the bot in in minutes to answer user queries.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/knowledge-ai"
        >
          More on knowledge ai &nbsp; &#8594;
        </a>
        <span className="font-section-small-signup-form-medium mb-2">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={knowAi3} alt="Upload Files"></img>
      </>
    ),
  },
  {
    header: "Connect External Sources",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Connect External Sources
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
            Put your knowledge base and documents posted in SharePoint,
            Confluence etc to better use and let users find answers effortlessly
            using the bot.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue "
            href="/conversational-ai-platform/knowledge-ai"
          >
            More on knowledge ai &nbsp; &#8594;
          </a>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={knowAi4} alt="Connect External Sources"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Connect External Sources
        </h3>

        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Put your knowledge base and documents posted in SharePoint, Confluence
          etc to better use and let users find answers effortlessly using the
          bot.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/knowledge-ai"
        >
          More on knowledge ai &nbsp; &#8594;
        </a>

        <img src={knowAi4} alt="Connect External Sources"></img>
      </>
    ),
  },
  {
    header: "Custom Answers",
    hasGridLayoutLarge: true,
    hasGridLayoutHeight: true,
    isComingSoon: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container  d-flex flex-column justify-content-center h-100 position-relative">
          <h3 className="font-section-normal-text-medium-link">
            Custom Answers
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
            Create or import custom answers to questions with ease that are not
            mentioned in your knowledge base.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue "
            href="/conversational-ai-platform/knowledge-ai"
          >
            More on knowledge ai &nbsp; &#8594;
          </a>
          <span className="font-section-small-signup-form-medium position-absolute bottom-30px">
            {" "}
            <sup
              style={{
                top: "0em",
                color: "#E05959",
                fontSize: "16px",
              }}
            >
              *
            </sup>
            Coming Soon
          </span>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={knowAi5} alt="Custom Answers"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Custom Answers</h3>

        <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
          Create or import custom answers to questions with ease that are not
          mentioned in your knowledge base.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/knowledge-ai"
        >
          More on knowledge ai &nbsp; &#8594;
        </a>
        <span className="font-section-small-signup-form-medium mb-2">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={knowAi5} alt="Custom Answers"></img>
      </>
    ),
  },
  {
    header: "Connect Website",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    isComingSoon: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container  d-flex flex-column justify-content-center h-100 position-relative">
          <h3 className="font-section-normal-text-medium-link">
            Connect Website
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
            Knowldge should not be confined to documents alone. Connect bot to
            your public or private website to help respond user queries easily
            in seconds.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/knowledge-ai"
          >
            More on knowledge ai &nbsp; &#8594;
          </a>
          <span className="font-section-small-signup-form-medium position-absolute bottom-30px">
            {" "}
            <sup
              style={{
                top: "0em",
                color: "#E05959",
                fontSize: "16px",
              }}
            >
              *
            </sup>
            Coming Soon
          </span>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={knowAi6} alt="Connect Website"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Connect Website
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
          Knowldge should not be confined to documents alone. Connect bot to
          your public or private website to help respond user queries easily in
          seconds.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/knowledge-ai"
        >
          More on knowledge ai &nbsp; &#8594;
        </a>
        <span className="font-section-small-signup-form-medium mb-2">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={knowAi6} alt="Connect Website"></img>
      </>
    ),
  },
  {
    header: "User Feedback",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            User Feedback
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            You can easily collect feedback from users regarding the assistance
            they’ve received from your bot. By collecting feedback, you can
            maintain a high standard of service quality. It allows you to
            identify and address any shortcomings promptly, ensuring a
            consistent and positive user experience.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container d-flex justify-content-center">
          <img src={knowAi7} alt=" User Feedback"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">User Feedback</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          You can easily collect feedback from users regarding the assistance
          they’ve received from your bot. By collecting feedback, you can
          maintain a high standard of service quality. It allows you to identify
          and address any shortcomings promptly, ensuring a consistent and
          positive user experience.
        </p>

        <img src={knowAi7} alt=" User Feedback"></img>
      </>
    ),
  },
];
const tabsForAppWorkflow = [
  {
    header: "Multi-step Workflows",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">
            Multi-step Workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
            Most chatbots only automate conversations and its only half solution
            to your problems. Only workativ offers bot and app workflow
            automation in single platform so your chatbot can truly automate
            your support. Integrate chatbot with apps in single click and
            automate your end-user issues or requests in seconds.
          </p>

          <a
            className="font-section-normal-text-medium font-section-app-workflow text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/chatbot-automation"
          >
            More on app workflow automation &nbsp; &#8594;
          </a>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={workFlow1} alt="Multi-step Workflows"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Multi-step Workflows
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
          Most chatbots only automate conversations and its only half solution
          to your problems. Only workativ offers bot and app workflow automation
          in single platform so your chatbot can truly automate your support.
          Integrate chatbot with apps in single click and automate your end-user
          issues or requests in seconds.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/chatbot-automation"
        >
          More on app workflow automation &nbsp; &#8594;
        </a>
        <img src={workFlow1} alt="Multi-step Workflows"></img>
      </>
    ),
  },
  {
    header: "Complex Workflows",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Complex Workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Effortlessly create complex workflows tailored to your business
            processes and requirements. Utilize a range of features like
            Conditions, Functions, Approvals, and more, all with a no-code
            approach. Complex tasks are simplified, saving time and reducing the
            risk of errors.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={workFlow2} alt="Complex Workflows"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Complex Workflows
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18  pb-0">
          Effortlessly create complex workflows tailored to your business
          processes and requirements. Utilize a range of features like
          Conditions, Functions, Approvals, and more, all with a no-code
          approach. Complex tasks are simplified, saving time and reducing the
          risk of errors.
        </p>

        <img src={workFlow2} alt="Complex Workflows"></img>
      </>
    ),
  },
  {
    header: "Workflow Functions",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Workflow Functions
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Take control of your data outputs with Workflow Functions. Easily
            modify the information received from your applications to match your
            desired output. Deliver information in a format that is easy to
            understand and work with.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={workFlow3} alt=" Workflow Functions"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Workflow Functions
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Take control of your data outputs with Workflow Functions. Easily
          modify the information received from your applications to match your
          desired output. Deliver information in a format that is easy to
          understand and work with.
        </p>

        <img src={workFlow3} alt=" Workflow Functions"></img>
      </>
    ),
  },
  {
    header: "Schedule Workflow",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Schedule Workflow
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Effortlessly automate tasks with precise timing, ensuring consistent
            and reliable operations. Plan ahead, boost productivity, and
            allocate resources optimally by scheduling workflow triggers at
            specific intervals, freeing your team to focus on more strategic
            activities and enhancing overall operational efficiency.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={workFlow4} alt="Schedule Workflow"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Schedule Workflow
        </h3>

        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Effortlessly automate tasks with precise timing, ensuring consistent
          and reliable operations. Plan ahead, boost productivity, and allocate
          resources optimally by scheduling workflow triggers at specific
          intervals, freeing your team to focus on more strategic activities and
          enhancing overall operational efficiency.
        </p>

        <img src={workFlow4} alt="Schedule Workflow"></img>
      </>
    ),
  },
  {
    header: "Application Triggers",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,

    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Application Triggers
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            With Application Triggers, you can set up tailored workflows in your
            app, making sure you never miss important updates. This boosts your
            decision-making and collaboration. Whether it’s managing passwords,
            surveys, OKRs, or personal info changes, or even surprising your
            team on special occasions, Application Triggers keep everyone in
            sync, so nothing slips through the cracks.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={workFlow5} alt="Application Triggers"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Application Triggers
        </h3>

        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          With Application Triggers, you can set up tailored workflows in your
          app, making sure you never miss important updates. This boosts your
          decision-making and collaboration. Whether it’s managing passwords,
          surveys, OKRs, or personal info changes, or even surprising your team
          on special occasions, Application Triggers keep everyone in sync, so
          nothing slips through the cracks.
        </p>

        <img src={workFlow5} alt="Application Triggers"></img>
      </>
    ),
  },
  {
    header: "Approvals",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Approve your chatbot requests in seconds
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18">
            Automating workplace requests can sometimes require manager or
            special approval. Workativ helps you automate and manage approvals
            for employee requests from chatbot easily out-of-the box with
            approval management capability.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Setting up approvals, notification, reminders, expiry, and tracking
            made simple for you.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={workFlow6} alt="Approvals"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Approve your chatbot requests in seconds
        </h3>

        <p className="font-section-normal-text-testimonials line-height-18">
          Automating workplace requests can sometimes require manager or special
          approval. Workativ helps you automate and manage approvals for
          employee requests from chatbot easily out-of-the box with approval
          management capability.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Setting up approvals, notification, reminders, expiry, and tracking
          made simple for you.
        </p>

        <img src={workFlow6} alt="Approvals"></img>
      </>
    ),
  },
  {
    header: "On-prem Connector",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            On-prem app secure connector
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
            Use workativ’s  secure connector  to connect chatbot with your
            on-prem apps behind your corporate firewall to execute workflows
            without a hitch. No need to open ports.
          </p>
          <a
            className="font-section-normal-text-medium font-section-app-workflow  text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/on-prem-app-connector"
          >
            More on on-prem app connect &nbsp; &#8594;
          </a>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={workFlow7} alt="On-prem Connector"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          On-prem app secure connector
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
          Use workativ’s  secure connector  to connect chatbot with your on-prem
          apps behind your corporate firewall to execute workflows without a
          hitch. No need to open ports.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/on-prem-app-connector"
        >
          More on on-prem app connect &nbsp; &#8594;
        </a>
        <img src={workFlow7} alt="On-prem Connector"></img>
      </>
    ),
  },
  {
    header: "App Workflow Marketplace",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            App workflows for marketplace
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Connect your chatbot with over 100+ apps and 1000+ app workflows so
            you can automate requests and auto resolve issues instantly from
            chatbot. Easily download, connect, and activate in few clicks. No
            coding.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={workFlow8} alt="App Workflow Marketplace"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          App workflows for marketplace
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Connect your chatbot with over 100+ apps and 1000+ app workflows so
          you can automate requests and auto resolve issues instantly from
          chatbot. Easily download, connect, and activate in few clicks. No
          coding.
        </p>
        <img src={workFlow8} alt="App Workflow Marketplace"></img>
      </>
    ),
  },
  {
    header: "Analytics",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Analyze app workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Analytics help you identify the number of app workflows triggered by
            chatbot. Track most or least used workflow for a given period,
            helping you fine-tune workflows as well.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={workFlow9} alt="Analytics"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Analyze app workflows
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Analytics help you identify the number of app workflows triggered by
          chatbot. Track most or least used workflow for a given period, helping
          you fine-tune workflows as well.
        </p>
        <img src={workFlow9} alt="Analytics"></img>
      </>
    ),
  },
  {
    header: "Workflow logs",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            App workflow logs
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Use logs to see the entire app workflow execution path from chatbot
            and drill down on specifics for more in-depth analysis. Know what
            went wrong if the workflow failed to execute, review input data for
            workflow and use log data to fix the issue.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={workFlow10} alt="workflow logs"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          App workflow logs
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Use logs to see the entire app workflow execution path from chatbot
          and drill down on specifics for more in-depth analysis. Know what went
          wrong if the workflow failed to execute, review input data for
          workflow and use log data to fix the issue.
        </p>
        <img src={workFlow10} alt="workflow logs"></img>
      </>
    ),
  },
];
const tabsForSharedLiveBox = [
  {
    header: "Multi-channel Inbox",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container ">
          <h3 className="font-section-normal-text-medium-link">
            Multi-channel Inbox
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-2">
            Our multi-channel shared chat inbox is AI-enhanced and designed for
            speed and efficiency—so your team can work smarter and collaborate
            faster.
          </p>
          <a
            className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue"
            href="/conversational-ai-platform/shared-inbox"
          >
            More on shared live inbox &nbsp; &#8594;
          </a>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox1} alt="Multi-channel Inbox"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Multi-channel Inbox
        </h3>

        <p className="font-section-normal-text-testimonials line-height-18 mb-2 pb-0">
          Our multi-channel shared chat inbox is AI-enhanced and designed for
          speed and efficiency—so your team can work smarter and collaborate
          faster.
        </p>
        <a
          className="font-section-normal-text-medium text-decoration-underline text-underline-offset color-anchor-blue mb-3"
          href="/conversational-ai-platform/shared-inbox"
        >
          More on shared live inbox &nbsp; &#8594;
        </a>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox1} alt="Multi-channel Inbox"></img>
      </>
    ),
  },
  {
    header: "Actions",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Actions</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Super power your live agents with workflow automatons. Connect with
            apps and resolve time consuming tasks and workflows in seconds,
            without ever leaving your inbox.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox2} alt="Actions"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Actions</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Super power your live agents with workflow automatons. Connect with
          apps and resolve time consuming tasks and workflows in seconds,
          without ever leaving your inbox.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox2} alt="Actions"></img>
      </>
    ),
  },
  {
    header: "Team Collaborations",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Team Collaborations
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Resolve complex issues quickly with inbox optimized for team
            collaboration using @ Mentions and real-time notifications.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox3} alt="Team Collaborations"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Team Collaborations
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Resolve complex issues quickly with inbox optimized for team
          collaboration using @ Mentions and real-time notifications.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox3} alt="Team Collaborations"></img>
      </>
    ),
  },
  {
    header: "AI Suggestions",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            AI Suggestions
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Put your knowledge base to good use. Let AI leverage your content to
            be the perfect co-pilot for your agents using AI based suggestions
            for faster response.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox4} alt="AI Suggestions"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        {" "}
        <h3 className="font-section-normal-text-medium-link">AI Suggestions</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Put your knowledge base to good use. Let AI leverage your content to
          be the perfect co-pilot for your agents using AI based suggestions for
          faster response.
        </p>{" "}
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox4} alt="AI Suggestions"></img>
      </>
    ),
  },
  {
    header: "AI Summary",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">AI Summary</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Enable your agents to assist your users faster with AI assisted
            conversation summary.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox5} alt="AI Summary"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        {" "}
        <h3 className="font-section-normal-text-medium-link">AI Summary</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Enable your agents to assist your users faster with AI assisted
          conversation summary.
        </p>{" "}
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox5} alt="AI Summary"></img>
      </>
    ),
  },
  {
    header: "Create Teams",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Create Teams</h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Customize your support approach by setting up specialized teams for
            different departments like IT, HR, Finance, Legal, and more. Assign
            agents to these teams, ensuring user inquiries are directed to the
            experts who can provide the best assistance.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox6} alt="Create Teams"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        {" "}
        <h3 className="font-section-normal-text-medium-link">Create Teams</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Customize your support approach by setting up specialized teams for
          different departments like IT, HR, Finance, Legal, and more. Assign
          agents to these teams, ensuring user inquiries are directed to the
          experts who can provide the best assistance.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox6} alt="Create Teams"></img>
      </>
    ),
  },
  {
    header: "User Personalization",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            User Personalization
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            With our Live Chat Inbox, you can access user details like their
            name, location, chat history, and feedback history. With these
            details, you can engage users more meaningfully, making them feel
            valued and understood.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox7} alt="User Personalization"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          User Personalization
        </h3>

        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          With our Live Chat Inbox, you can access user details like their name,
          location, chat history, and feedback history. With these details, you
          can engage users more meaningfully, making them feel valued and
          understood.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox7} alt="User Personalization"></img>
      </>
    ),
  },
  {
    header: "Live Agent Transfers",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Live Agent Transfers
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Transfer complex problems from chatbot to your live support teams
            without interruption—so your users get the best possible experience.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox8} alt="Live Agent Transfers"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Live Agent Transfers
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Transfer complex problems from chatbot to your live support teams
          without interruption—so your users get the best possible experience.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox8} alt="Live Agent Transfers"></img>
      </>
    ),
  },
  {
    header: "Canned Responses",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Canned Responses
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Say goodbye to typing out repetitive messages. Create pre-defined
            responses that your agents can quickly send to users. This feature
            saves time and ensures consistency in your user interactions.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox9} alt="Canned Responses"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        {" "}
        <h3 className="font-section-normal-text-medium-link">
          Canned Responses
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Say goodbye to typing out repetitive messages. Create pre-defined
          responses that your agents can quickly send to users. This feature
          saves time and ensures consistency in your user interactions.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox9} alt="Canned Responses"></img>
      </>
    ),
  },
  {
    header: "Chat Customization",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Chat Customization
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            You can add a title and tags to the chat for a personalized and
            efficient support experience. Tags are like keywords or labels you
            attach to the conversation. These tags help you categorize chats by
            specific topics or issue issues. It enables you to identify and
            address users’ most common and repetitive issues.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox10} alt="Chat Customization"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Chat Customization
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          You can add a title and tags to the chat for a personalized and
          efficient support experience. Tags are like keywords or labels you
          attach to the conversation. These tags help you categorize chats by
          specific topics or issue issues. It enables you to identify and
          address users’ most common and repetitive issues.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox10} alt="Chat Customization"></img>
      </>
    ),
  },
  {
    header: "Set Agent Availability",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Set Agent Availability
          </h3>

          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Agents can define their working hours and availability. They can
            specify when they can connect with users and provide support. This
            feature ensures that agents are online and active during peak hours
            or when users typically require assistance.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox11} alt="Set Agent Availability"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">
          Set Agent Availability
        </h3>

        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Agents can define their working hours and availability. They can
          specify when they can connect with users and provide support. This
          feature ensures that agents are online and active during peak hours or
          when users typically require assistance.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox11} alt="Set Agent Availability"></img>
      </>
    ),
  },
  {
    header: "Chat Routing",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Chat Routing</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Efficiently route and assign chats to agents or teams based on
            various criteria, such as maximum utilization, average utilization,
            or even random assignment. This ensures optimal resource allocation
            and quick issue resolution.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox12} alt="Chat Routing"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Chat Routing</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Efficiently route and assign chats to agents or teams based on various
          criteria, such as maximum utilization, average utilization, or even
          random assignment. This ensures optimal resource allocation and quick
          issue resolution.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox12} alt="Chat Routing"></img>
      </>
    ),
  },
  {
    header: "Branding",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Branding</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Your brand, your chat interface. You can add your company name,
            logo, colors, and more to the chat interface, making every
            interaction an extension of your brand identity.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox13} alt="Branding"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Branding</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Your brand, your chat interface. You can add your company name, logo,
          colors, and more to the chat interface, making every interaction an
          extension of your brand identity.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox13} alt="Branding"></img>
      </>
    ),
  },
  {
    header: "Dashboard",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Dashboard</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Real-time dashboards and monitoring views make it easy to take
            action and optimize your team’s performance.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox14} alt="Dashboard"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Dashboard</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Real-time dashboards and monitoring views make it easy to take action
          and optimize your team’s performance.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox14} alt="Dashboard"></img>
      </>
    ),
  },
  {
    header: "Chat History",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">Chat History</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Don’t miss a beat. Our Live Chat Inbox allows you to review chat
            histories between agents and users. Filter chats based on location,
            tags, agents, teams, and more, giving you valuable insights into
            user interactions and helping you refine your support strategies.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={liveInbox15} alt="Chat History"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-normal-text-medium-link">Chat History</h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Don’t miss a beat. Our Live Chat Inbox allows you to review chat
          histories between agents and users. Filter chats based on location,
          tags, agents, teams, and more, giving you valuable insights into user
          interactions and helping you refine your support strategies.
        </p>
        <span className="font-section-small-signup-form mb-3">
          {" "}
          <sup
            style={{
              top: "0em",
              color: "#E05959",
              fontSize: "16px",
            }}
          >
            *
          </sup>
          Coming Soon
        </span>
        <img src={liveInbox15} alt="Chat History"></img>
      </>
    ),
  },
];
const allFeatureImages = [
  conAi1,
  conAi2,
  conAi3,
  conAi4,
  conAi5,
  conAi6,
  conAi7,
  conAi8,
  conAi9,
  conAi10,
  conAi11,
  conAi12,
  conAi13,
  conAi14,
  conAi15,
  conAi16,

  knowAi1,
  knowAi2,
  knowAi3,
  knowAi4,
  knowAi5,
  knowAi6,
  knowAi7,

  liveInbox1,
  liveInbox2,
  liveInbox3,
  liveInbox4,
  liveInbox5,
  liveInbox6,
  liveInbox7,
  liveInbox8,
  liveInbox9,
  liveInbox10,
  liveInbox11,
  liveInbox12,
  liveInbox13,
  liveInbox14,
  liveInbox15,

  workFlow1,
  workFlow2,
  workFlow3,
  workFlow4,
  workFlow5,
  workFlow6,
  workFlow7,
  workFlow8,
  workFlow9,
  workFlow10,
];
const tabsForOnBoarding = [
  {
    header: "Sign up",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Sign up</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Create a new bot or download pre-built bots from marketplace
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_one} alt="Sign up"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Sign up</h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
          Create a new bot or download pre-built bots from marketplace
        </p>
        <img src={onboard_one} alt="Sign up"></img>
      </>
    ),
  },
  {
    header: "Download Pre-built Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Bot Marketplace
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Bot templates are pre-built bots with conversations covering most
            common and repetitive support topics.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Easily download bots in one-click, add new or edit existing support
            topics in minutes.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_two} alt="Bot Marketplace"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Bot Marketplace</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Bot templates are pre-built bots with conversations covering most
          common and repetitive support topics.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Easily download bots in one-click, add new or edit existing support
          topics in minutes.
        </p>
        <img src={onboard_two} alt="Bot Marketplace"></img>
      </>
    ),
  },
  {
    header: "Connect App Workflows",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Connect App Workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Connecting chatbot with your apps and building app workflows
            requires a lot of development efforts, time, and costs.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18">
            But workativ provides pre-built app integrations and app workflows
            so you can connect your chatbot with your app and automate tasks
            instantly.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 color-grey mb-0 pb-0">
            No coding.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_three} alt="Connect App Workflows"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Connect App Workflows</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Connecting chatbot with your apps and building app workflows requires
          a lot of development efforts, time, and costs.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          But workativ provides pre-built app integrations and app workflows so
          you can connect your chatbot with your app and automate tasks
          instantly.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 color-grey mb-0">
          No coding.
        </p>
        <img src={onboard_three} alt="Connect App Workflows"></img>
      </>
    ),
  },
  {
    header: "Test your Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Bot Testing</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Test your bot in real time using “Try Me”. Make adjustments, add or
            edit conversations, fine tune user interactions, and review your bot
            conversations on the go before you go-live.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_four} alt="Test your Bot"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Bot Testing</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Test your bot in real time using “Try Me”. Make adjustments, add or
          edit conversations, fine tune user interactions, and review your bot
          conversations on the go before you go-live.
        </p>
        <img src={onboard_four} alt="Test your Bot"></img>
      </>
    ),
  },
  {
    header: "Go Live",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Deploy on Teams, Slack, or as Chat Widget
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Turn your favorite collaboration app into modern employee
            self-service by deploying chatbot on your Teams, Slack or as Widget
            on your self-help portal in few clicks.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18">
            Customize the end-user experience by applying your company branding,
            logo etc.
          </p>
          <div className="icons_container_onboarding">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_five} alt="Go Live"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">
          Deploy on Teams, Slack, or as Chat Widget
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Turn your favorite collaboration app into modern employee self-service
          by deploying chatbot on your Teams, Slack or as Widget on your
          self-help portal in few clicks.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Customize the end-user experience by applying your company branding,
          logo etc.
        </p>
        <div className="icons_container_onboarding mb-3">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={onboard_five} alt="Go Live"></img>
      </>
    ),
  },
];
const tabsForOnBoardingImages = [
  onboard_one,
  onboard_two,
  onboard_three,
  onboard_four,
  onboard_five,
];
